import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ImageTiles from "../components/image-tiles"
import FeaturesTabs from "../components/features-tabs"
import FeaturesTiles from "../components/features-tiles"
import Section from "../components/section"
import Page from "../components/page"
import MoreInfoTiles  from "../components/more-info-tiles"
import colours from "../utils/colours"
import styled from "styled-components"

let Media = styled.div`
  height: 0;
  padding-top: 56.25%;
  position: relative;
  outline-style: none;
  video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    outline-style: none;
    border: none;
  }
  .gatsby-image-wrapper{
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    outline-style: none;
  }
`

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "home") {
                  seo {
                      title
                      metaDesc
                  }
                  homepage {
                      title
                      text
                      image1 {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid(maxWidth: 900) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      image2 {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid(maxWidth: 900) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      image3 {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid(maxWidth: 900) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      tilesTitle
                      tilesText
                  }
              }
          }
      }
  `)
  let seoData = data.wpgraphql.pageBy.seo
  let pageData = data.wpgraphql.pageBy.homepage

  return (
    <Page
      seoTitle={ seoData.title  }
      seoDescription={ seoData.metaDesc }
    >
      <Section>
        <h1 dangerouslySetInnerHTML={{ __html: pageData.title }}/>
        <p dangerouslySetInnerHTML={{ __html: pageData.text }}/>
        <ImageTiles
          image1={ pageData.image1 }
          image2={ pageData.image2 }
          image3={ pageData.image3 }
        />
      </Section>

      <Section
        backGColour={ colours.highlightGrey }
      >
        <h2 dangerouslySetInnerHTML={{ __html: pageData.tilesTitle }}/>
        <p dangerouslySetInnerHTML={{ __html: pageData.tilesText }}/>

        <FeaturesTabs/>

        <FeaturesTiles/>
      </Section>

      <Section backGColour={ 'white' }>
        <MoreInfoTiles
          location={ location }
        />
      </Section>

    </Page>
  )
}
export default IndexPage
