import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import colours from "../utils/colours"
import Img from "gatsby-image"
import widths from "../utils/dimensions"

let TileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px 0 0 0;
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    display: none;
  }
`
let Tile = styled.div`
  width: 100%;
  margin-bottom: 30px;
  background-color: white;
  &:last-of-type{
    margin-bottom: 0;
  }
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    width: calc(50% - 15px);
  }
`
let Image = styled(Img)`
  width: 100%;
  padding-top: 56.25%;
  height: 0;
`
let Text = styled.div`
  padding: 40px 30px;
  h2{
    text-transform: uppercase;
    color: ${ colours.blue };
  }
  p{
    text-align: left;
  }
`

const FeaturesTiles = () => {
  const componentData = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "home") {
                  homepage {
                      featureTiles {
                          text
                          title
                          image {
                              sourceUrl
                              altText
                              imageFile {
                                  childImageSharp {
                                      fluid {
                                          ...GatsbyImageSharpFluid_withWebp
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `)
  let data = componentData.wpgraphql.pageBy.homepage.featureTiles

  return(
    <TileContainer>

      { data.map(( { title, text, image }, index ) => {
        return (

          <Tile key={ index }>
            <Image
              fluid={ image.imageFile.childImageSharp.fluid }
              alt={ image.altText }
            />
            <Text>
              <h2 dangerouslySetInnerHTML={{ __html: title }}/>
              <p>{ text }</p>
            </Text>
          </Tile>

        )})}

    </TileContainer>

  )
}
export default FeaturesTiles