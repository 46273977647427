import React from "react";
import styled from "styled-components"
import Img from "gatsby-image"
import widths from "../utils/dimensions"

let Container = styled.div`
  width: 100%;
  padding: 50px 0 0 0;
  @media screen and (min-width: 600px){
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
  }
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    grid-column-gap: 20px;
  }
`
let Tile = styled.div`
  margin-bottom: 10px;
  .gatsby-image-wrapper{
    height: 0;
    padding-bottom: 70%;
    img{
      object-fit: cover;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
  @media screen and (min-width: 600px){
    margin-bottom: 0;
    .gatsby-image-wrapper{
      padding-bottom: 75%;
    }
  }
`

const ImageTiles = ({ image1, image2, image3 }) => {
  return(
    <Container>
      <Tile>
        <Img
          fluid={ image1.imageFile.childImageSharp.fluid }
          alt={ image1.altText }
        />
      </Tile>
      <Tile>
        <Img
          fluid={ image2.imageFile.childImageSharp.fluid }
          alt={ image2.altText }
        />
      </Tile>
      <Tile>
        <Img
          fluid={ image3.imageFile.childImageSharp.fluid }
          alt={ image3.altText }
        />
      </Tile>
    </Container>
  )
}
export default ImageTiles


