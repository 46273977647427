import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import colours from "../utils/colours"
import Img from "gatsby-image"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import widths from "../utils/dimensions"

let StyledTabs = styled(Tabs)`
  display: none;
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    padding: 50px 0 0 0;
    position: relative;
    display: block;
  }
`
let StyledTabsList = styled(TabList)`
  list-style: none; 
  margin: 0;
  width: 28%;
  display: inline-block;
  position: relative;
  top: 0;
  transform: translate(0px, 0px);
  float: left;
  background-color: white;
`
let StyledTab = styled(Tab)`
  width: 100%;
  margin: 0;
  cursor: pointer;
  padding: 15px 30px;
  text-align: right;
  display: flex;
  text-transform: uppercase;
  color: black;
  position: relative;
  border-bottom: 1px solid ${ colours.highlightGrey };  
  outline: none;
  div{
    width: calc(100% - 35px);
    line-height: 40px;
    padding-right: 20px;
    text-align: left;
  }
  span{
    width: 35px;
    height: 35px;
    border: 1px solid ${ colours.lightGrey };
    border-radius: 20px;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    position: absolute;
  }
  &:last-of-type{
    border-bottom: none;
  }
  &:hover{
    color: ${ colours.red };
    span{
      border: 1px solid ${ colours.red };
    }
  }
  &.react-tabs__tab--selected{
    color: ${ colours.blue };
    span{
      border: 1px solid ${ colours.blue };
    }
    &:hover{
      color: ${ colours.red };
      span{
        border: 1px solid ${ colours.red };
      }
    }
  }
`
let StyledTabPanel = styled(TabPanel)`
  visibility: hidden;
  width: 0;
  height: 0;
  position: fixed;
  right: -9999px;
  &.react-tabs__tab-panel--selected{
    display: inline-block;
    visibility: visible;
    width: 72%;
    height: auto;
    right: 0;
    position: relative;
  }
`
let TabContent = styled.div`
  margin-left: 20px;
  text-align: center;
  padding-bottom: 35px;
  background-color: white;
`
let Image = styled(Img)`
  width: 100%;
  padding-top: 56.25%;
  height: 0;
`
let Video = styled.div`
  width: 100%;
  padding-top: 65%;
  height: 0;
  position: relative;
  margin-bottom: -35px;
  video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    outline-style: none;
    border: none;
  }
`
let Text = styled.div`
  width: calc(100% - 70px); 
  margin: -70px auto 0 auto;
  padding: 35px 20px;
  text-align: left;
  background-color: rgba(0,0,0,1);
  color: white;
  position: relative;
`

const FeaturesTabs = () => {
  const componentData = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "home") {
                  homepage {
                      featueTilesVideoDesktopName
                      featueTilesVideoDesktopPoster {
                          altText
                          sourceUrl
                          imageFile {
                              childImageSharp {
                                  fluid {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      featueTilesVideoDesktop {
                          mediaItemUrl
                          mediaFile {
                              publicURL
                          }
                      }
                      featureTiles {
                          text
                          title
                          image {
                              sourceUrl
                              altText
                              imageFile {
                                  childImageSharp {
                                      fluid (maxWidth: 3080, quality: 100) {
                                          ...GatsbyImageSharpFluid_withWebp
                                      }
                                  }
                              }
                          }
                      }
                      
                  }
              }
          }
      }
  `)
  let data = componentData.wpgraphql.pageBy.homepage.featureTiles
  let videoData = componentData.wpgraphql.pageBy.homepage
  let videoPoster = componentData.wpgraphql.pageBy.homepage.featueTilesVideoDesktopPoster

  return(
    <StyledTabs
      forceRenderTabPanel={ true }
    >
      <StyledTabsList>
        <StyledTab>
          <div>{ videoData.featueTilesVideoDesktopName }</div><span/>
        </StyledTab>

        { data.map(( { title }, index ) => {
          return (

            <StyledTab key={ index }>
              <div>{ title }</div><span/>
            </StyledTab>

          )})}

      </StyledTabsList>
        <StyledTabPanel>
          <TabContent>
            <Video>
              <video
                controls={ true }
                className={ 'lozad' }
                preload="none"
                poster={ videoPoster && videoPoster.imageFile.childImageSharp.fluid.src  }
              >
                <source
                  src={ videoData.featueTilesVideoDesktop.mediaFile.publicURL }
                  type="video/mp4"
                />
              </video>
            </Video>
          </TabContent>
        </StyledTabPanel>

      { data.map(( { title, text, image }, index ) => {
        return (

          <StyledTabPanel key={ index }>
            <TabContent>
              <Image
                fluid={ image.imageFile.childImageSharp.fluid }
                alt={ image.altText }
              />
              <Text>
                <h2 dangerouslySetInnerHTML={{ __html: title }}/>
                <p dangerouslySetInnerHTML={{ __html: text }}/>
              </Text>
            </TabContent>
          </StyledTabPanel>

        )})}

    </StyledTabs>
  )
}
export default FeaturesTabs